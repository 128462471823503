exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guias-js": () => import("./../../../src/pages/guias.js" /* webpackChunkName: "component---src-pages-guias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-como-usar-proxy-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/como-usar-proxy/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-como-usar-proxy-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-mejor-vpn-comparativa-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/mejor-vpn-comparativa/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-mejor-vpn-comparativa-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-mejores-servidores-proxy-gratis-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/mejores-servidores-proxy-gratis/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-mejores-servidores-proxy-gratis-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-ofertas-vpn-2022-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/ofertas-vpn-2022/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-ofertas-vpn-2022-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-preguntas-frecuentes-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/preguntas-frecuentes/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-preguntas-frecuentes-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-protocolos-vpn-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/protocolos-vpn/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-protocolos-vpn-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-que-es-proxy-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/que-es-proxy/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-que-es-proxy-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-que-es-vpn-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/que-es-vpn/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-que-es-vpn-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-ventajas-de-usar-vpn-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/ventajas-de-usar-vpn/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-src-posts-ventajas-de-usar-vpn-index-mdx" */),
  "component---src-templates-vpn-js": () => import("./../../../src/templates/vpn.js" /* webpackChunkName: "component---src-templates-vpn-js" */)
}

